<template>
    <div>
      <FormulaBanner :title="title" :formulaCode="formulaCode"/>
      <div class="index">
        <!-- 表单 -->
        <div>
          <div class="top_nav"><span></span>多重耐药菌感染风险预测
          </div>
          <div class="formBody">
            <!-- 表单 -->
            <van-form @submit="onSubmit">
              <MyPatient :memberId="memberId" :selectValue="selectValue" @change="userChange"/>
              <van-field
                  v-model="zhuanruqianyuanTime"
                  :rules="[{ required: true,  message: '请填写整数'},{ pattern: /^[+]{0,1}(\d+)$/,message: '请填写整数'}]"
                  class="error"
              >
                <template #input>
                  <div class="titles">
                    <span class="titlesName">转入前住院时间 <i>*</i></span>
                    <span style="display: flex;">
                     <span class="information">
                       <van-cell-group class="vanCell">
                         <van-field input-align="right"  v-model="zhuanruqianyuanTime" placeholder="请输入" />
                       </van-cell-group>
                     </span>
                     <div class="unit"><span>天</span></div>
                   </span>
                  </div>
                </template>
              </van-field>
              <van-field
                  v-model="zhuanruqianICU"
                  :rules="[{ required: true,  message: '请填写整数'},{ pattern: /^[+]{0,1}(\d+)$/,message: '请填写整数'}]"
                  class="error"
              >
                <template #input>
                  <div class="titles">
                    <span class="titlesName">转入前ICU住院时间 <i>*</i></span>
                    <span style="display: flex;">
                     <span class="information">
                       <van-cell-group class="vanCell">
                         <van-field input-align="right"  v-model="zhuanruqianICU" placeholder="请输入" />
                       </van-cell-group>
                     </span>
                     <div class="unit"><span>天</span></div>
                   </span>
                  </div>
                </template>
              </van-field>
              <div class="question">
                <p>是否长期卧床 <span style="color:red">*</span></p>
                <van-field name="radio" :rules="[{required: true,message: '请选择一个选项',trigger: 'onChange',},]">
                  <template #input>
                   <van-radio-group v-model="changqiwochuang" direction="horizontal" style="display: flex;justify-content: space-between;width: 67%;">
                     <van-radio name="32">是</van-radio>
                     <van-radio name="0">否</van-radio>
                   </van-radio-group>
                 </template>
                </van-field>
              </div>
              <div class="question">
                <p>是否患慢性肺部疾病 <span style="color:red">*</span></p>
                <van-field name="radio" :rules="[{required: true,message: '请选择一个选项',trigger: 'onChange',},]">
                  <template #input>
                   <van-radio-group v-model="manxingfeibing" direction="horizontal" style="display: flex;justify-content: space-between;width: 67%;">
                     <van-radio name="19.4">是</van-radio>
                     <van-radio name="0">否</van-radio>
                   </van-radio-group>
                 </template>
                </van-field>
              </div>
              <div class="question">
                <p>是否低蛋白血症（白蛋白＜30g/L） <span style="color:red">*</span></p>
                <van-field name="radio" :rules="[{required: true,message: '请选择一个选项',trigger: 'onChange',},]">
                  <template #input>
                   <van-radio-group v-model="didanbai" direction="horizontal" style="display: flex;justify-content: space-between;width: 67%;">
                     <van-radio name="33.5">是</van-radio>
                     <van-radio name="0">否</van-radio>
                   </van-radio-group>
                 </template>
                </van-field>
              </div>
              <!-- <TemplateFiveList :type="'radio'" :list-data="listData" :selectValue="listSelect" @change="listChange"></TemplateFiveList> -->
              <van-field
                  v-model="Apache"
                  :rules="[{ required: true,  message: '请填写整数'},{ pattern: /^[+]{0,1}(\d+)$/,message: '请填写整数'}]"
                  class="error"
              >
                <template #input>
                  <div class="titles">
                    <span class="titlesName" style="display: flex;">
                      <div style="width:140px"> ApacheⅡ评分<i>*</i></div>
                     
                      <van-popover
                       v-model="show2Popover"
                       theme="dark"
                       trigger="click"
                       placement="right-start"
                       style="width: 30%"
                     >
                       <div style="width: 160px;padding: 15px">
                         转入后第一次ApacheⅡ评分
                       </div>
                       <template #reference>
                         <van-icon name="info" color="#A3A3A3" />
                       </template>
                    </van-popover>
                    </span>
                    <span style="display: flex;">
                     <span class="information">
                       <van-cell-group class="vanCell">
                         <van-field input-align="right"  v-model="Apache" placeholder="请输入" />
                       </van-cell-group>
                     </span>
                     <div class="unit" ><span>分</span></div>
                   </span>
                  </div>
                </template>
              </van-field>
              <!-- <TemplateFiveList :type="'radio'" :list-data="list2Data" :selectValue="list2Select" @change="list2Change"></TemplateFiveList> -->
              <div class="question">
                <p>转入前抗生素使用种类数 <span style="color:red">*</span></p>
                <van-field name="radio" :rules="[{required: true,message: '请选择一个选项',trigger: 'onChange',},]">
                  <template #input>
                   <van-radio-group v-model="kangshengsuType" direction="horizontal" style="display: flex;justify-content: space-between;width: 100%;">
                     <van-radio name="0">0</van-radio>
                     <van-radio name="15.67">1</van-radio>
                     <van-radio name="31.34">2</van-radio>
                     <van-radio name="47.01">≥3</van-radio>
                   </van-radio-group>
                 </template>
                </van-field>
              </div>
              <div class="question">
                <p>入ICU前是否使用抗生素 <span style="color:red">*</span></p>
                <van-field name="radio" :rules="[{required: true,message: '请选择一个选项',trigger: 'onChange',},]">
                  <template #input>
                   <van-radio-group v-model="iSkangshengsu" direction="horizontal" style="display: flex;justify-content: space-between;width: 67%;">
                     <van-radio name="24">是</van-radio>
                     <van-radio name="0">否</van-radio>
                   </van-radio-group>
                 </template>
                </van-field>
              </div>
              <div class="question">
                <p>入ICU前是否进行侵入性操作 <span style="color:red">*</span></p>
                <van-field name="radio" :rules="[{required: true,message: '请选择一个选项',trigger: 'onChange',},]">
                  <template #input>
                   <van-radio-group v-model="qinruxing" direction="horizontal" style="display: flex;justify-content: space-between;width: 67%;">
                     <van-radio name="23.4">是</van-radio>
                     <van-radio name="0">否</van-radio>
                   </van-radio-group>
                 </template>
                </van-field>
              </div>
              <div v-if="compute === true" style="margin: 16px;display: flex;justify-content: center;">
                <van-button class="formula-template-button" block type="info"  native-type="submit" style="width:90px">提交</van-button>
              </div>
            </van-form>
          </div>
        </div>
        <!-- 计算结果 -->
        <CalculationResults v-if="show" :countText="countText" :countTextList="countTextList"></CalculationResults>
        <!-- 后台设置的值 -->
        <div v-if="tableData.explain && tableData.explain.length > 0">
          <div v-for="(item,index) in tableData.explain" :key="index" class="StandardDescription">
            <div class="top_nav"><span></span>{{item.title}}</div>
            <div class="StandardDeatil">
              <span v-html="item.value">
              </span>
            </div>
          </div>
        </div>
        <!-- 参考文献 -->
        <div style="margin-bottom:50px;" v-if="tableData.references && tableData.references.length > 0">
          <div class="StandardDescription">
            <div class="top_nav"><span></span>参考文献</div>
            <div class="StandardDeatil2">
              <!-- <span v-html="item.value">
              </span> -->
              <span v-for="(item,index) in tableData.references" :key="item.title" >
              <div v-if="index !== 0" style="margin: 0 -10px 10px -10px;border-top: 1px solid #E7E7E7"></div>
                <div v-html="index+ 1 + '、' + item.title" class="references" @click="openLink(item.link)">
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  
  </template>
  
  <script>
  import MyPatient from '@/components/MyPatient.vue'
  import FormulaBanner from '@/components/FormulaBanner.vue'
  import {Toast} from "vant";
  import {getAdmins} from "@/utils/adminDate";
  import {setById} from "@/service/base";
  import {getFormula, getFormulaHuiXian, postFormula} from "@/service/BodyMassIndex_api";
  import CalculationResults from "@/components/CalculationResults";
  // import TemplateFiveList from "@/components/Template5/TemplateFiveList";
// import { color } from 'echarts';
// import { reduce } from 'lodash';
  export default {
    name: "medicalSearch",
    data(){
      return{
        listData: [
          {
            title: '是否长期卧床 <span style="color:red">*</span>',
            columns: ['是', '否'],
            fenzhi: [32,0] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
          },
          {
            title: '是否患慢性肺部疾病 <span style="color:red">*</span>',
            columns: ['是', '否'],
            fenzhi: [19.4,0] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
          },
          {
            title: '是否低蛋白血症（白蛋白＜30g/L） <span style="color:red">*</span>',
            columns: ['是', '否'],
            fenzhi: [33.5,0] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
          }
        ],
        changqiwochuang: '',
        manxingfeibing:'',
        didanbai:'',
        kangshengsuType:'',
        iSkangshengsu:'',
        qinruxing:'',
        list2Data:[
          {
            title: '转入前抗生素使用种类数 <span style="color:red">*</span>',
            columns: ['0', '1','2','3'],
            fenzhi: [0,15.67,31.34,47.01] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
          },
          {
            title: '入ICU前是否使用抗生素 <span style="color:red">*</span>',
            columns: ['是', '否'],
            fenzhi: [24,0] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
          },
          {
            title: '入ICU前是否进行侵入性操作 <span style="color:red">*</span>',
            columns: ['是', '否'],
            fenzhi: [23.4,0] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
          }
        ],
        listSelect: [],
        list2Select: [],
        columns: ["mg/dL", "μmol/L"],
        compute:true,
        // redFirst:0,
        // redSecond:0,
        show:false,
        shengao:'',
        zhuanruqianyuanTime:'',
        zhuanruqianICU:'',
        radio:1,
        countTextList:[],
        formulaCode: '',
        Apache:'',
        yingjizhishu: '',
        title: '多重耐药菌感染风险预测',
        memberId: '1111',
        selectValue: '',
        username:"",
        value:"",
        showPopover: false,
        show2Popover: false,
        nianling: '',
        // xingbie: null,
        tableData: {},
        countText: '',
        patientId: null,
        result: null,
        yingjizhishuzhi: '',
        // tizhong: '',
        xueqingnanongdu: '',
        kValue:0,
        fenzhi2List:[],
        fenzhiList:[],
      }
    },
    components: {
      FormulaBanner,
      MyPatient,
      CalculationResults,
      // TemplateFiveList
    },
    async created() {
      await this.test()
      await this.getTableData()
    },
    watch:{
      "zhuanruqianyuanTime":{
        immediate:true,
        handler:function(){
          this.show = false;
        }
      },
      "zhuanruqianICU":{
        immediate:true,
        handler:function(){
          this.show = false;
        }
      },
      "changqiwochuang":{
        immediate:true,
        handler:function(){
          this.show = false;
        }
      },
      "manxingfeibing":{
        immediate:true,
        handler:function(){
          this.show = false;
        }
      },
      "iSkangshengsu":{
        immediate:true,
        handler:function(){
          this.show = false;
        }
      },
      "kangshengsuType":{
        immediate:true,
        handler:function(){
          this.show = false;
        }
      },
      "didanbai":{
        immediate:true,
        handler:function(){
          this.show = false;
        }
      },
      "qinruxing":{
        immediate:true,
        handler:function(){
          this.show = false;
        }
      },
      "Apache":{
        immediate:true,
        handler:function(){
          this.show = false;
        }
      },
    },
    methods:{
      coumputTetxt () {
        // // console.log(this.listSelect,this.list2Select)
        // // 单选
        // let fenshu = 0
        // for (let i = 0; i < this.fenzhiList.length; i++) {
        //   fenshu += this.fenzhiList[i]
        // }
        // let fen2shu = 0
        // for (let i = 0; i < this.fenzhi2List.length; i++) {
        //     fen2shu += this.fenzhi2List[i]
        // }
        // 填空
        let zhuyuanqian = 0
        if(this.zhuanruqianyuanTime <= 120){
            zhuyuanqian = this.zhuanruqianyuanTime*5/6
        }else{
            zhuyuanqian = 100
        }
        let zhuyuanICU = 0
        if(this.zhuanruqianyuanTime <= 120){
            zhuyuanICU = this.zhuanruqianICU*50/75
        }else{
            zhuyuanICU = 50
        }
        let Apache = 0
        if(this.Apache <= 40){
            Apache = this.Apache*1.5
        }else{
            Apache = 60
        }
        this.result = (Number(this.changqiwochuang) + Number(this.manxingfeibing) + Number(this.didanbai) + Number(this.kangshengsuType) + Number(this.iSkangshengsu) + Number(this.qinruxing) + zhuyuanqian + zhuyuanICU + Apache).toFixed(2)

        if( this.result <= 192){
          this.countText = `${this.result} 不需要预隔离`
        }else{
          this.countText = `${this.result} 需要预隔离`
        }
      },
      listChange (result, fenzhi) {
        this.show=false
        this.listSelect = result //结果数组
        this.fenzhiList = fenzhi  //分值数组
      },
      list2Change (result, fenzhi) {
        this.show=false
        this.list2Select = result //结果数组
        this.fenzhi2List = fenzhi  //分值数组
      },
      async test(){
        //像接口头部传用户信息
        let arrays = await getAdmins(this.$route.query.memberId,this.$route.query.channel)
        this.channel = arrays.os
        this.version = arrays.version
        this.ip = arrays.ip
        await setById(arrays)
      },
      async getTableData () {
        const data = {
          channel: this.$router.currentRoute.query.channel,
          code: 'KGCKDOLL',
        }
        const res = await getFormula(data.channel, data.code)
        if (res.code === 0) {
          this.tableData = res.data
          this.formulaCode = res.data.code
        }
        if (this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' && this.$router.currentRoute.query.logId) {
          const data = {
            id: this.$router.currentRoute.query.logId
          }
          const res = await getFormulaHuiXian(data)
          if (res.code === 0) {
            // this.tableData = res.data
            // this.formulaCode = res.data.code
            this.zhuanruqianICU = res.data.content.zhuanruqianICU.value
            this.zhuanruqianyuanTime = res.data.content.zhuanruqianyuanTime.value
            this.Apache = res.data.content.Apache.value
            this.changqiwochuang = res.data.content.changqiwochuang.value
            this.didanbai = res.data.content.didanbai.value
            this.manxingfeibing = res.data.content.manxingfeibing.value
            this.kangshengsuType = res.data.content.kangshengsuType.value
            this.iSkangshengsu = res.data.content.iSkangshengsu.value
            this.qinruxing = res.data.content.qinruxing.value
            if (res.data.patientId) this.selectValue = {id: res.data.patientId, name: res.data.patientName}
            this.coumputTetxt()
          }
          this.$nextTick(() => {
            this.show=true
          })
          // this.compute = false
        }
      },
      calculateAge(birthDateString) {
        const today = new Date();
        const birthDate = new Date(birthDateString);
        let age = today.getFullYear() - birthDate.getFullYear();
        // 如果今天的日期还没到生日月份和日期，则年龄减一
        if (today.getMonth() < birthDate.getMonth() ||
            (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
          age--;
        }
        return age;
      },
      // 开参考文献链接
      openLink(u){
        if (!u) {
          return
        }
        //获取系统版本
        let userAI = navigator.userAgent;
        const isAndroid = userAI.indexOf('Android') > -1 || userAI.indexOf('Linux') > -1; //Android终端
        const isIOS = !!userAI.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
        if (isAndroid) {
          // alert('安卓操作系统')
          // 这个是安卓操作系统
          window.open(u)
        }
        if (isIOS) {
          // 这个是ios操作系统
          // alert('ios操作系统')
          window.location.href = u
        }
      },
      async onSubmit(){
        // if(this.listSelect.length !== 3){
        //   console.log("111",this.listSelect)
        //   this.redFirst = true
        //   return
        // }else{
        //   this.redFirst = false
        // }
        // this.redFirst = Math.random() //开启验证
        // this.redSecond = Math.random() //开启验证
        this.coumputTetxt()
        const data = {
          id: this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' ? this.$router.currentRoute.query.logId : null,
          patientId: this.patientId,
          // columnCode: this.tableData.columnCode,
          columnCode: '0',
          formulaCode: this.tableData.code,
          channel: this.$router.currentRoute.query.channel,
          location: this.$router.currentRoute.query.location,
          content: {
            zhuanruqianyuanTime: {value: this.zhuanruqianyuanTime, unit: '天'},
            zhuanruqianICU: {value: this.zhuanruqianICU, unit: '天'},
            Apache: {value: this.Apache, unit: '分'},
            changqiwochuang: {value: this.changqiwochuang, unit: '分'},
            manxingfeibing: {value: this.manxingfeibing, unit: '分'},
            didanbai: {value: this.didanbai, unit: '分'},
            kangshengsuType: {value: this.kangshengsuType, unit: '分'},
            iSkangshengsu:{value: this.iSkangshengsu, unit: '分'},
            qinruxing: {value: this.qinruxing, unit: '分'},
          },
          result: {
            fenshu:{value: this.result},
            DisplayResults:this.countText
          }
        }
        // if (this.xingbie == 1) {
        //   data.result.nanxing = {value: this.result, unit: 'kcal'}
        //   data.result.DisplayResults = this.result+'kcal/d' 
        // } else {
        //   data.result.nvxing = {value: this.result, unit: 'kcal'}
        //   data.result.DisplayResults = this.result+'kcal/d'
        // }
        const res = await postFormula(data)
        if (res.code === 0) {
          Toast.success('提交成功')
          this.show=true
        } else {
          Toast.fail(res.message)
          this.show=true
        }
      },
      userChange (data) {
        if (data.birthday && data.birthday !== '') {
          this.nianling = this.calculateAge(data.birthday)
        } else {
          this.nianling = ''
        }
        // this.xingbie = data.sex
        this.patientId = data.id
        this.selectValue = data
      },
    }
  }
  </script>
  
  
  <style scoped lang="scss">
  .index{
    background: #F6F6F6;
    padding: 10px 15px;
    height: 100vh;
    .StandardDescription{
      margin-top:10px;
      .top_nav{
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: start;
        margin-bottom:10px;
        span {
          display: inline-block;
          width: 5px;
          height: 15px;
          background: #259beb;
          margin-right: 5px;
        }
      }
      .StandardDeatil{
        width:100%;
        padding:15px 10px;
        //   height: 50px;
        background-color: #fff;
        box-sizing: border-box;
      }
    }
    .top_nav{
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom:10px;
      span {
        display: inline-block;
        width: 5px;
        height: 15px;
        background: #259beb;
        margin-right: 5px;
      }
    }
    .formBody{
      background-color: #fff;
      padding-bottom:10px;
      .CalculationResultsBox{
        padding:0 8px;
        .CalculationResults{
          font-size: 16px;
          color: #333333;
          font-weight: bold;
          display: flex;
          justify-content: center;
          margin-bottom:10px
        }
        .CalculationDetail{
          font-size: 15px;
          color: #333333;
          // font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: start;
          margin-bottom:10px;
          background-color: #f6f6f6;
          // height: 16px;
          span{
            display: inline-block;
            width: 3px;
            height: 24px;
            background: #fe9712;
            margin-right: 5px;
          }
        }
      }
    }
    .titles{
      display:flex;
      align-items: center;
      justify-content: space-between;
      width:100%;
      height: 50px;
      border-bottom: 1px solid #f0f0f0;
      padding: 0 10px;
      box-sizing: border-box;
      .titlesName{
        font-size:16px;
        i{
          color: red;
        }
      }
      .information{
        font-size:16px;
        margin-right:4px;
        .PleaseSelect{
          color: #b9b9b9;
        }
        .vanCell{
          width:50px;
          padding:0;
          height: 100%;
        }
      }
      .vBotton{
        width:60px
      }
      .unit{
        height: 50px;
        //   line-height: 50px;
        display: flex;
        align-items: center;
        font-size:16px;
        width:20px;
      }
      .vanIcon{
        font-size:13px;
        color:#a8a8a8;
        font-weight: 650;
      }
    }
  }
  ::v-deep .van-cell {
    padding:0px 0px;
  }
  ::v-deep .van-field__control{
    height: 50px;
    font-size: 16px;
  }
  .error {
    ::v-deep .van-field__error-message {
      position: absolute;
      top: 30px;
      left: 10px;
    }
  }

  .StandardDeatil2{
    width:100%;
    padding: 15px 10px;
    padding-bottom:1px;
    //   height: 50px;
    background-color: #fff;
    box-sizing: border-box;
    .references{
      margin-bottom:15px
    }
  }
  ::v-deep p {
    padding: 0;
    margin: 0;
  }
//   .setborder{
//     border-top: 1px solid #f0f0f0;
//     padding:0 16px;
//     .title2 {
//     // padding:0px 0 0 10px;
//     font-size:16px;
//     color:#323233;
//     // margin-top:10px;
//     }
// }
.question{
  padding:10px;
  border-bottom: 1px solid #f0f0f0;
}
  </style>
  